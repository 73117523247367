// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-affiliate-disclosure-tsx": () => import("./../../../src/pages/affiliate-disclosure.tsx" /* webpackChunkName: "component---src-pages-affiliate-disclosure-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-who-are-we-tsx": () => import("./../../../src/pages/who-are-we.tsx" /* webpackChunkName: "component---src-pages-who-are-we-tsx" */),
  "component---src-templates-post-template-docs-tsx": () => import("./../../../src/templates/PostTemplateDocs.tsx" /* webpackChunkName: "component---src-templates-post-template-docs-tsx" */),
  "component---src-templates-reviews-template-tsx": () => import("./../../../src/templates/ReviewsTemplate.tsx" /* webpackChunkName: "component---src-templates-reviews-template-tsx" */)
}

